
<div class="wrapper">
    <div class="sidebar" data-color="white" data-background-color="red" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar-1.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <!-- <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div> -->
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
</div>
